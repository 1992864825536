function userreducer(state, action) {
    switch (action.type) {
        case "USER_LOGGEDIN":
            return action.payload

        case "USER_SIGNOUT":
            return action.payload

        default:
            return state
    }
}

export default userreducer
