const endpoint = "https://backend.sydneylimoshire.com.au"

const SignUpUser = async (para) => {
    // console.log(para)
    const res = await fetch(`${endpoint}/signUp`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...para })
    })

    const data = await res.json();
    return data;
}

const LogInUser = async (para) => {
    // console.log(para)
    const res = await fetch(`${endpoint}/logIn`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...para })
    })

    const data = await res.json();
    return data;
}

const BookNowApi = async (para) => {
    // console.log(para)
    const res = await fetch(`${endpoint}/bookNow`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({ ...para })
    })

    const data = await res.json();
    return data;
}

const alreadyLogin = async () => {
    const res = await fetch(`${endpoint}/`,{
        method: "POST",
        headers: {
            "Content-type": "application/json"
        },
    })
    const data = await res.json();
    return data;
}

const logOutUser = async () => {
    const res = await fetch(`${endpoint}/signOut`, {
        method: "POST",
        credentials: "include",
    })
    const data = await res.json();
    return data;
}

const getCars = async ({ queryKey }) => {
    // console.log(queryKey[1])
    const res = await fetch(`${endpoint}/cars?bags=${queryKey[1]}&passengers=${queryKey[2]}`);
    const data = await res.json()
    return data
}

// https://sydneylimoshire.foundation/
// https://sydneylimoshire.foundation/logIn

const updateCarPriceApi = async (para) => {
    // console.log(para)
    const res = await fetch(`${endpoint}/cars${para._id}`, {
        headers: {
            'Content-Type': 'application/json'
        },
        method: "PUT",
        body: JSON.stringify(para),
    });

    const data = await res.json();
    return data;
}

const addDate = async (value) => {
    // console.log(value)
    const res = await fetch(`${endpoint}/addDate`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(value)
    })

    const data = await res.json()
    return data;
}

const getDate = async (value) => {
    // console.log(value);
    const res = await fetch(`${endpoint}/getDate`, {
        method: "POST",
        body: JSON.stringify({
            date: value
        }),
        headers: {
            "Content-Type": "application/json"
        }
    });
    const data = await res.json()
    return data;
}

const getAllDates = async (value) => {
    // console.log(value);
    const res = await fetch(`${endpoint}/getAllDates`);
    const data = await res.json()
    return data;
}

const deleteDate = async (value) => {
    // console.log(value);
    const res = await fetch(`${endpoint}/deleteDate${value}`);
    const data = await res.json()
    return data;
}

const getTestimonials = async () => {
    // console.log(value);
    const res = await fetch(`${endpoint}/testimonials`);
    const data = await res.json()
    return data?.data || [];
}

const addTestimonials = async (body) => {
    const res = await fetch(`${endpoint}/addtestimonial`, {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
    const data = await res.json()
    return data;
}

const deleteTestimonial = async (id) => {
    const res = await fetch(`${endpoint}/addtestimonial/${id}`, {
        method: "DELETE"
    })
    const data = await res.json()
    return data;
}

const processBankPayment = async (value) => {
    // console.log(value);
    const res = await fetch(`${endpoint}/processbank-payment`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(value)
    })
    const data = await res.json();
    return data;
}

const getStatusCode = async () => {
    const res = await fetch('https://api.apilayer.com/number_verification/countries?apikey=jG4cXveDIf1E4qnAANQIgE1SCeqF1cSC');

    const data = await res.json();
    return Object.values(data);
}

const validatePhoneNumber = async ({ queryKey }) => {
    const res = await fetch(`https://phonevalidation.abstractapi.com/v1/?api_key=b42274c7941545518c25d20c32bab4e0&phone=${queryKey[0]} `);
    const data = await res.json();

    return data;
}

export { SignUpUser, LogInUser, BookNowApi, alreadyLogin, logOutUser, getCars, updateCarPriceApi, addDate, getDate, processBankPayment, getAllDates, deleteDate, getStatusCode, validatePhoneNumber, getTestimonials, addTestimonials, deleteTestimonial }