import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import "./LogIn.css"
import CloseIcon from '@mui/icons-material/Close';
import * as EmailValidator from 'email-validator';
import { SignUpUser } from './apis/api';
import { useMutation } from "react-query"
import { Spin } from 'antd';
import { Context } from './context/Provider';
import { notificationToastify } from './toastify/toast';

function SignUp({ setlogIn, setsignUp }) {
    const [signUpData, setsignUpData] = useState({
        name: "",
        email: "",
        password: "",
        isAdmin: false,
    })
    const { data, isLoading, mutate, error } = useMutation("userSignUp", SignUpUser)
    const { userdispatch } = useContext(Context)

    useEffect(() => {
        if (error) {
            console.log(error)
        }
    }, [error])

    useEffect(() => {
        if (data) {
            data.error ? notificationToastify(data.error, 'error') : (() => {

                userdispatch({ type: "USER_LOGGEDIN", payload: { name: data._doc.name, email: data._doc.email, isAdmin: data._doc.isAdmin } })
                notificationToastify("User Successfully SignedUp", 'success');
                localStorage.setItem('sydney_lioms_hires', JSON.stringify({ name: data._doc.name, email: data._doc.email, isAdmin: data._doc.isAdmin }) )
            })()  
            setlogIn(false)
        }
    }, [data])

    const signupinformation = (e) => {
        const { name, value } = e.target;

        setsignUpData((val) => {
            return {
                ...val,
                [name]: value
            }
        })
    }

    const signUpuserbtn = () => {
        if (!EmailValidator.validate(signUpData.email)) {
            notificationToastify('Please Enter a valid Email', 'error')
        }
        else if (!(signUpData.email && signUpData.name && signUpData.password)) {
            notificationToastify('Please Fill all the fields!', 'error')
        }
        else {
            mutate({ ...signUpData })
        }
    }

    return (
        <AnimatePresence exitBeforeEnter >
            <motion.div className='backdrop__form' initial={{ y: "-100vh" }} animate={{ y: 0 }} transition={{ delay: 0.3 }} >
                <button className='modal__closebtn' onClick={() => { setlogIn(false) }} > <CloseIcon /> </button>
                <h1 className='login__heading' >Get Your First Booking Done Now.</h1>
                <div className='input__containers' >
                    <input className='login__inputs' name='name' onChange={signupinformation} placeholder='Full Name' type="text" />
                    <span>*</span>
                </div>
                <div className='input__containers' >
                    <input className='login__inputs' name='email' onChange={signupinformation} placeholder='Email...' type="email" autoComplete='off' />
                    <span>*</span>
                </div>
                <div className='input__containers' >
                    <input className='login__inputs' name='password' onChange={signupinformation} placeholder='Password...' type='password' autoComplete='off' />
                    <span>*</span>
                </div>
                {/* <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline1" name="isAdmin" value="true" onChange={signupinformation} className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="customRadioInline1">Admin</label>
                </div>
                <div className="custom-control custom-radio custom-control-inline">
                    <input type="radio" id="customRadioInline2" name="isAdmin" value="false" onChange={signupinformation} className="custom-control-input" />
                    <label className="custom-control-label" htmlFor="customRadioInline2">Not Admin</label>
                </div> */}
                {
                    isLoading ? <Spin style={{ marginTop: "10px" }} size="large" /> :
                        <>
                            <motion.button onClick={signUpuserbtn} whileTap={{ scale: 0.9 }} className="btn btn-primary btn-lg" > SignUp </motion.button>
                            <div className='divider'>
                                <p>OR</p>
                            </div>
                            <button className='signup__toggle' onClick={() => setsignUp(false)} > LogIn </button>
                        </>
                }
            </motion.div>
        </AnimatePresence>
    )
}

export default SignUp
