import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Provider from './context/Provider';
import { QueryClient, QueryClientProvider } from 'react-query'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const client = new QueryClient()

ReactDOM.render(
  <LocalizationProvider dateAdapter={AdapterDayjs} >
    <QueryClientProvider client={client} >
      <Provider>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </QueryClientProvider>
  </LocalizationProvider>
  ,
  document.getElementById('root')
);

