import "./App.css"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "antd/dist/antd.css";
import { Helmet } from "react-helmet";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import React, { Suspense, useContext, useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Context } from "./context/Provider";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LogIn from "./LogIn";
import AddingTestimonial from "./AddingTestimonial";
const PaymentDone = React.lazy(() => import("./PaymentDone"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const BookNowPage = React.lazy(() => import("./BookNowPage"));
const ErrorPage = React.lazy(() => import("./ErrorPage"));
const ServicesPage = React.lazy(() => import("./pages/ServicesPage"));
const CheckOutPage = React.lazy(() => import("./CheckOutPage"));
const AdminPanel = React.lazy(() => import("./adminpanel/AdminPanel"))
const PaymentPage = React.lazy(() => import("./PaymentPageNew"))

function App() {
  const { userState, userdispatch } = useContext(Context)
  const [logIn, setlogIn] = useState(false);
  // console.log(userState)'

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('sydney_lioms_hires'));
    if (data) {
      userdispatch({
        type: "USER_LOGGEDIN",
        payload: data
      });
      setlogIn(false);
    }

    // window.addEventListener('beforeunload', () => {
    //   const check = window.confirm("Are you sure?.All your data will be lost.")
    //   debugger
    //   if (check) {
    //     return true;
    //   }
    //   else {
    //     return false;
    //   }
    //   // console.log(check);
    // })

    // return () => {
    //   window.removeEventListener('beforeunload', () => {

    //   });
    // }
  }, []);

  return (
    <>
      <Helmet>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Welcome | Sydney Limos Hire </title>
        <meta
          name="description"
          content="Welcome to Sydney limous hire one of sydney's best car rental companies, we always have vehicles avaiable and in stock one of the sydney's largest car rental companies, many different makes and models to choose from"
        />
        <meta
          name="keywords"
          content="sydney hire car, Sydney
    limousine, limous in Sydney, Australia chauffeur , chauffer in sydney,
    Chauffer in Australia, premium uber australia, Babyseat cars
    Australia/Sydney, corporate cars Australia/Sydney, Corporate cars,
    Premium Cars service , VIP car service, car with baby seats sydney,
    Van service in sydney,van service in Australia, luxury car service,
    ridesharing service in sydney/Australia, Premium ridesharing service,
    Luxury car service"
        />
      </Helmet>
      <div className="App">
        <BrowserRouter>
          <LogIn logIn={logIn} setlogIn={setlogIn} />
          <Header logIn={logIn} setlogIn={setlogIn} />
          <ToastContainer />
          <Routes>
            {(userState?.isAdmin && userState?.roles?.includes('panel')) && <Route path="/adminpanel" element={<Suspense fallback={"Loading..."} >
              <AdminPanel />
            </Suspense>} />}
            {/* {(userState?.isAdmin && userState?.roles?.includes('feedback')) && <Route path="/testimonials" element={<Suspense fallback={"Loading..."} >
              <AddingTestimonial />
            </Suspense>} />} */}
            <Route path="/" element={<Suspense fallback={<div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>} >
              <HomePage />
            </Suspense>} />
            < Route
              path="/services"
              element={<Suspense fallback={<div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} >
                <ServicesPage />
              </Suspense>}
            />
            <Route path="/book" element={
              <Suspense fallback={<div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
              } >
                <BookNowPage />
              </Suspense>
            } />
            <Route path="/checkoutpage" element={
              <Suspense fallback={<div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} >
                <CheckOutPage />
              </Suspense>} />
            <Route path="/payment" element={
              <Suspense fallback={<div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} >
                <PaymentPage />
              </Suspense>
            } />
            <Route path="/successful/:paramStatus" element={
              <Suspense fallback={<div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} >
                <PaymentDone />
              </Suspense>} />
            <Route path="*" element={
              <Suspense fallback={<div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>} >
                <ErrorPage />
              </Suspense>} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
