import { Flip, toast } from 'react-toastify';

export const notificationToastify = (text, type, autoClose = 1500) => {
    console.log(autoClose)
    toast(text, {
        position: "bottom-right",
        autoClose: autoClose,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        transition: Flip,
        type: type
    })
};