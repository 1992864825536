function reducer(state, action) {
    switch (action.type) {
        case 'ValueSelected':
            // console.log(action.payload)
            return { value: action.payload}

        default:
            return state

    }
}

export default reducer
