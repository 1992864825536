import React, { createContext, useReducer } from 'react'
import { formDataReducer } from './formdata';
import { grandtotalReducer } from './grandtotalReducer';
import reducer from './reducer';
import userreducer from './userreducer';

export const Context = createContext(null);

function Provider({ children }) {
    const [state, dispatch] = useReducer(reducer, { value: "" })
    const [userState, userdispatch] = useReducer(userreducer, JSON.parse(localStorage.getItem("sydney_lioms_hires")))
    const [grandTotal, grandTotaldispatch] = useReducer(grandtotalReducer, 0);
    const [formReducerData, formDatadispatch] = useReducer(formDataReducer, {
        passengers: "",
        date: new Date().toISOString(),
        luggage: "",
        largeLuggage: 0,
        mediumLuggage: 0,
        smallLuggage: 0,
        childSeats: "",
        capsuleSeat: 0,
        babySeat: 0,
        boosterSeat: 0,
        pickupLocation: "",
        dropoffLocation: "",
        airportType: "",
        flight_number: '',
        AirportTransportType: "",
        returnTrip: "",
        returnTripAsAbove: "",
        returnTripPickUpTime: new Date().toISOString(),
        returnTripPickUpLocation: "",
        returnTripDropoffLocation: "",
    })

    return (
        <Context.Provider value={{
            state, dispatch,
            userState, userdispatch,
            formReducerData, formDatadispatch,
            grandTotal, grandTotaldispatch
        }} >
            {children}
        </Context.Provider>
    )
}

export default Provider
