import { AnimatePresence, motion } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import "./LogIn.css"
import CloseIcon from '@mui/icons-material/Close';
import SignUp from './SignUp';
import * as EmailValidator from 'email-validator';
import { useMutation } from 'react-query';
import { Context } from './context/Provider';
import { LogInUser } from './apis/api';
import { Spin } from 'antd';
import { notificationToastify } from './toastify/toast';

const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 },
}

function LogIn({ logIn, setlogIn }) {
    const [logInData, setlogInData] = useState({
        email: "",
        password: "",
    })
    const [signUp, setsignUp] = useState(false)
    const { data, isLoading, mutate, error } = useMutation("userLogIn", LogInUser)
    const { userdispatch } = useContext(Context)

    useEffect(() => {
        if (error) {
            console.log(error)
        }
    }, [error])

    useEffect(() => {
        if (data) {
            data.error ?
                notificationToastify(data.error, 'error') : (() => {
                    userdispatch({ type: "USER_LOGGEDIN", payload: { name: data._doc.name, email: data._doc.email, isAdmin: data._doc.isAdmin, roles: data._doc.roles } })
                    notificationToastify("User Successfully LoggedIn", 'success')
                    localStorage.setItem('sydney_lioms_hires', JSON.stringify({ name: data._doc.name, email: data._doc.email, isAdmin: data._doc.isAdmin, roles: data._doc.roles }) )
                }
                )()
            setlogIn(false)
        }
    }, [data])

    const logInuserInformation = (e) => {
        const { name, value } = e.target;

        setlogInData((val) => {
            return {
                ...val,
                [name]: value
            }
        })
    }

    const loginUserbtn = () => {
        if (!(EmailValidator.validate(logInData.email))) {
            notificationToastify('Please Enter a valid Email!', 'error')
        }
        else if (!(logInData.email && logInData.password)) {
            notificationToastify('Please Fill all the required Fields!', 'error')
        }
        else {
            mutate({
                email: logInData.email,
            })
        }
    }

    return (
        <AnimatePresence exitBeforeEnter >
            {
                logIn && (<motion.div
                    className='backdrop'
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >
                    {signUp ? <SignUp setsignUp={setsignUp} setlogIn={setlogIn} /> :
                        <motion.div className='backdrop__form' initial={{ y: "-100vh" }} animate={{ y: 0 }} transition={{ delay: 0.3 }} >
                            <button className='modal__closebtn' onClick={() => { setlogIn(false) }} > <CloseIcon /> </button>
                            <h1 className='login__heading' >CONTINUE BOOKING</h1>
                            <div className='input__containers' >
                                <input className='login__inputs' name='email' onChange={logInuserInformation} placeholder='Email...' type="email" autoComplete='off' />
                                <span>*</span>
                            </div>
                            <div className='input__containers' >
                                <input className='login__inputs' name='password' onChange={logInuserInformation} placeholder='Password...' type='password' autoComplete='off' />
                                <span>*</span>
                            </div>
                            {
                                isLoading ? <Spin style={{ marginTop: "10px" }} size="large" /> :
                                    <>
                                        <motion.button onClick={loginUserbtn} whileTap={{ scale: 0.9 }} className="btn btn-primary btn-lg w-100"> LogIn </motion.button>
                                        <div className='divider'>
                                            <p>OR</p>
                                        </div>
                                        <button className='signup__toggle' onClick={() => setsignUp(true)} > SignUp </button>
                                    </>
                            }
                        </motion.div>
                    }
                </motion.div>)
            }
        </AnimatePresence>
    )
}

export default LogIn
