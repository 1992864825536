
export function grandtotalReducer(state, action) {
    switch (action.type) {
        case 'GRAND_TOTAL':
            console.log(action.payload)
        return action.payload;
    
        default:
            return state;
    }
}