import * as React from 'react';
// import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import { useQuery } from 'react-query';
import { addTestimonials, deleteTestimonial, getTestimonials } from './apis/api';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import DeleteIcon from '@mui/icons-material/Delete';
import {
    TextField,
    Container,
} from '@material-ui/core';

// const columns = [
//     { id: 'header', label: 'Header' },
//     { id: 'body', label: 'Body' },
//     {
//         id: 'user',
//         label: 'User',
//     },
//     {
//         id: 'role',
//         label: 'Role',
//     },
//     {
//         id: 'delete',
//         label: 'Delete',
//     },
// ];

// function createData(name, code, population, size) {
//     const density = population / size;
//     return { name, code, population, size, density };
// }

// const rows = [
//     createData('India', 'IN', 1324171354, 3287263),
//     createData('China', 'CN', 1403500365, 9596961),
//     createData('Italy', 'IT', 60483973, 301340),
//     createData('United States', 'US', 327167434, 9833520),
//     createData('Canada', 'CA', 37602103, 9984670),
//     createData('Australia', 'AU', 25475400, 7692024),
//     createData('Germany', 'DE', 83019200, 357578),
//     createData('Ireland', 'IE', 4857000, 70273),
//     createData('Mexico', 'MX', 126577691, 1972550),
//     createData('Japan', 'JP', 126317000, 377973),
//     createData('France', 'FR', 67022000, 640679),
//     createData('United Kingdom', 'GB', 67545757, 242495),
//     createData('Russia', 'RU', 146793744, 17098246),
//     createData('Nigeria', 'NG', 200962417, 923768),
//     createData('Brazil', 'BR', 210147125, 8515767),
// ];

const useStyles = makeStyles({
    card: {
        maxWidth: 400,
        margin: 'auto',
        marginTop: 20,
        padding: 16,
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 40,
    },
    inputField: {
        marginBottom: 20,
        width: "100%",
        '& label': {
            color: '#01D28E !important', // Change label color
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#01D28E !important', // Change border color
            },
            '&:hover fieldset': {
                borderColor: '#01D28E !important', // Change border color on hover
            },
            '&.Mui-focused fieldset': {
                borderColor: '#01D28E !important', // Change border color when focused
            },
        },
        '& input': {
            color: '#000000 !important', // Change text color
        },
    },
});

export default function AddingTestimonial() {
    const [addTestimonial, setaddTestimonial] = React.useState(false);
    const [testimonialData, settestimonialData] = React.useState({
        header: '',
        body: '',
        user: ""
    })
    const { data, isLoading, refetch } = useQuery(["testimonials"], getTestimonials, {
        // refetchOnWindowFocus: false,
        // enabled: false // disable this query from automatically running,
    });
    const classes = useStyles();

    const deleteTestimonialButton = async (id) => {
        const response = await deleteTestimonial(id);

        if (response?.status) {
            refetch();
            setaddTestimonial(false);

            settestimonialData({
                header: '',
                body: '',
                user: ""
            })
        }
    }

    const addTestimonialApi = async (e) => {
        e.preventDefault();
        if (!(testimonialData.header && testimonialData.body && testimonialData.user)) {
            alert("Fill All Details.");
            return;
        }
        const res = await addTestimonials(testimonialData);
        if (res?.status) {
            refetch();
            setaddTestimonial(false);

            settestimonialData({
                header: '',
                body: '',
                user: ""
            })
        }
    }

    const handleInputData = (e) => {
        const { name, value } = e.target;

        settestimonialData((val) => {
            return {
                ...val,
                [name]: value,
            }
        })
    }

    if (isLoading) {
        return <div>Loading...</div>
    }

    return (
        <div className='bg-dark ftco-section admin_panel' style={{ paddingTop: "6rem" }} >
            <div className='container mt-3 bg-white rounded p-3 border'>
                <div className='d-flex align-items-center justify-content-between' >
                    <h3>{!addTestimonial ? 'Added Testimonials' : 'Add Testimonial'} </h3>

                    <Button onClick={() => setaddTestimonial(!addTestimonial)} variant="contained">{addTestimonial ? 'See Added' : 'Add'}</Button>
                </div>
                {!addTestimonial ? <div>
                    {data?.data?.length ? data?.data?.map((testimonial) => {
                        return <Card className={classes.card} key={testimonial._id}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    {testimonial?.header}
                                </Typography>
                                <Typography variant="body1">
                                    {testimonial.body}
                                </Typography>
                                <Typography variant="subtitle2" color="textSecondary">
                                    - {testimonial.user}
                                </Typography>
                            </CardContent>
                            <Button onClick={() => deleteTestimonialButton(testimonial._id)} color="secondary" size="small" variant="text"><DeleteIcon className='deleteIcon_svg' /> </Button>
                        </Card>
                    }) : <Card className={classes.card} >
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                No Testimonial Added
                            </Typography>
                            <Typography variant="body1">
                                --
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                --
                            </Typography>
                        </CardContent>
                    </Card>}
                    {/* <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.length ? (
                                    data?.data?.map((row, i) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                                                {
                                                    Object.keys(row).map((eachKey) => {
                                                        return <TableCell key={eachKey} >{row[eachKey]}</TableCell>
                                                    })
                                                }
                                                
                                            </TableRow>
                                        );
                                    })) : (
                                    <TableCell colSpan={5} align="center">
                                        No data available
                                    </TableCell>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper> */}
                </div> : <div>
                    <Container maxWidth="sm">
                        <form className={classes.formContainer} onSubmit={addTestimonialApi}>
                            <TextField
                                className={classes.inputField}
                                label="Header"
                                // variant="outlined"
                                name="header"
                                value={testimonialData.header}
                                onChange={handleInputData}
                            />
                            <TextField
                                className={classes.inputField}
                                label="Body"
                                // variant="outlined"
                                name="body"
                                value={testimonialData.body}
                                onChange={handleInputData}
                            />
                            <TextField
                                className={classes.inputField}
                                label="User"
                                // variant="outlined"
                                name="user"
                                value={testimonialData.user}
                                onChange={handleInputData}
                            />
                            <Button type="submit" variant="contained" color="primary">
                                Submit
                            </Button>
                        </form>
                    </Container>
                </div>}
            </div>
        </div>
    );
}
