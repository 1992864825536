import React from 'react'
import "./Footer.css"
import { Link } from "react-router-dom";
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';

function Footer() {
    return (
        // <>hello</>
        <footer className="ftco-footer ftco-bg-dark ftco-section ">
            <div className="container ">
                <div className="row mb-5 ">
                    <div className="col-md ">
                        <div className="ftco-footer-widget mb-4 ">
                            <h2 className="ftco-heading-2 ">
                                <Link to="#"><img style={{ height: "60px", width: "200px" }} src="https://res.cloudinary.com/dotyuf0ls/image/upload/v1658017330/limoNew-Black_fec2bu.png"
                                    alt="SydneyLimosHire Best sydney chauffer services" />
                                </Link>
                            </h2>
                            <p>Better than delayed perfection is constant improvement. So simply put the burden of the
                                transportation issue on us.</p>
                            <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-5 ">
                                <li><Link style={{display: "flex", alignItems: "center", justifyContent: "center"}} to="/"><TwitterIcon /></Link></li>
                                <li><Link style={{display: "flex", alignItems: "center", justifyContent: "center"}} to="/"><FacebookIcon /></Link></li>
                                <li><Link style={{display: "flex", alignItems: "center", justifyContent: "center"}} to="/"><InstagramIcon /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-md"></div>
                 <div className="col-md"></div>
                    <div className="col-md ">
                        <div className="ftco-footer-widget mb-4 ">
                            <h2 className="ftco-heading-2 ">Have some Questions?</h2>
                            <div className="block-23 mb-3 ">
                                <ul>
                                    <li style={{display: "flex", alignItems: "center"}} ><LocationOnIcon className='icon' /><span className="text ">Floor 16 365-377 Kent
                                        Street Sydney CBD 2000</span></li>
                                    <li style={{display: "flex", alignItems: "center"}} ><a href="tel:+61416158685"><PhoneIcon className='icon' /><span className="text ">+61416158685</span></a></li>
                                    <li style={{display: "flex", alignItems: "center"}} ><a
                                        href="https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=GTvVlcSHwQVvxrbDCxrvCpVbBbjFRZrDNZQbzsljJNDmnVLMSdljcmcphqMxcVpXwDGrPkbSBZkTg"><EmailIcon className='icon' /><span
                                                className="text">bookings@sydneylimoshire.com.au</span>
                                    </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        // <div classNameName='Footer' >
        //     <div classNameName='footer__inner' >
        //         <div>
        //             <h3>For Enquiries or Booking Contact US on:</h3>
        // <p> <strong> Phone: </strong> <Link href="tel:+61404210744">+61404210744</Link></p>
        // <p> <strong> Email: </strong> <Link href="mailto: bookings@sydneylimoshire.com.au" >bookings@sydneylimoshire.com.au</Link> </p>
        //         </div>
        //         <div>
        //             <h3>Contact Us on our Social Media Accounts:</h3>
        //             <div style={{ display: "flex" }} >
        //                 <p style={{ color: "#fb3958" }} > <InstagramIcon /> </p>
        //                 <p style={{ color: "#3B5998" }} > <FacebookIcon /> </p>
        //             </div>
        //         </div>
        //     </div>
        //     <strong style={{ display: "flex", alignItems: "center", padding: "1rem 0rem", justifyContent: "center" }} >All Rights Reserved for <CopyrightIcon /> SYDNEY LIMOS HIRE</strong>
        // </div>
    )
}

export default Footer
