import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Context } from "./context/Provider";
import "./Header.css";
import { logOutUser } from "./apis/api";
import { useMutation } from "react-query";
import MenuIcon from '@mui/icons-material/Menu';
import { notificationToastify } from "./toastify/toast";

function Header({ setlogIn }) {
  const location = useLocation()
  const [toggle, settoggle] = useState(false);
  const { userState, userdispatch } = useContext(Context);
  // const { data, mutate, isLoading } = useMutation("logout", logOutUser);
  const { pathname } = location

  const logOutUserbtn = () => {
    setlogIn(false);
    userdispatch({ type: "USER_SIGNOUT", payload: null });
    localStorage.clear('sydney_lioms_hires')
  };

  // useEffect(() => {
  //   if (data) {
  //     notificationToastify(data.message, 'success')
  //     userdispatch({ type: "USER_SIGNOUT", payload: null });
  //   }
  // }, [data]);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light" id="ftco-navbar">
      <div className="container">
        <Link to="/" className="navbar-brand">Sydney<span>LimosHire</span>

        </Link>
        <button onClick={() => settoggle(!toggle)} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
          <MenuIcon /> Menu
        </button>

        <div style={{ justifyContent: "flex-end", display: toggle ? "block" : "none" }} className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item ${pathname === "/" && "active"}`}><Link to="/" className="nav-link">Home</Link></li>

              <li className={`nav-item ${pathname === "/about-us/" && "active"}`}><Link to="/about-us/" className="nav-link">About Us</Link></li>
              <li className={`nav-item ${pathname === "/services" && "active"}`}><Link to="/services" className="nav-link">Services</Link></li>
               <li className={`nav-item ${pathname === "/contact-us/" && "active"}`}><Link to="/contact-us/" className="nav-link">Contact Us</Link></li>
            <li className="nav-item nav-link" >
              {
                userState ? (
                  <div className="d-flex flex-column">
                    <strong style={{ color: "white" }}>Hello {userState?.name || "User"}</strong>
                    <div className="d-flex align-items-center justify-content-between" >
                      <div>
                        {(userState?.isAdmin && userState?.roles?.includes('panel')) &&
                          <Link to="/adminpanel" >
                            <button className="btn btn-warning mb-1" >Admin Panel</button>
                          </Link>}
                        {(userState?.isAdmin && userState?.roles?.includes('feedback')) &&
                          <Link to="/testimonials" >
                            <button className="btn btn-warning mb-1 ml-1" >Add Testimonial</button>
                          </Link>}
                      </div>
                      <button
                        className="btn btn-secondary ml-2"
                        style={{ color: "black" }}
                        onClick={logOutUserbtn}
                      >
                        SignOut
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="login__btn btn btn-success"
                    onClick={() => {
                      setlogIn(true);
                    }}
                  >
                    LOG IN
                  </button>
                )
              }
            </li>
          </ul>
        </div>
      </div>
    </nav>


    // <Menu
    //   className={`Header ${toggle ? "header_scrolled" : ""}`}
    //   onClick={handleClick}
    //   selectedKeys={[currentItem.current]}
    //   mode="horizontal"
    // >
    //   <Menu.Item>
    //     <Link to="/">
    //       <img
    //         className="logo"
    //         src="https://res.cloudinary.com/dotyuf0ls/image/upload/v1642084567/SydneylimoshireFinal_gvskrm.jpg"
    //         alt="Sydney Limos Hire Logo"
    //       />
    //     </Link>
    //   </Menu.Item>
    //   <Menu.Item key="home">
    //     <Link to="/">HOME</Link>
    //   </Menu.Item>
    //   <Menu.Item key="about">
    //     <Link to="/about">ABOUT US</Link>
    //   </Menu.Item>
    //   <Menu.Item key="services">
    //     <Link to="/services">SERVICES</Link>
    //   </Menu.Item>
    //   <Menu.Item key="fleets">
    //     <Link to="/fleets">FLEET</Link>
    //   </Menu.Item>
    //   {/* <Menu.Item key="contact" >
    //             <Link to="/" >CONTACT US</Link>
    //         </Menu.Item> */}
    //   {isLoading ? (
    //     <Menu.Item>
    //       {" "}
    //       <Spin style={{ marginTop: "10px" }} size="large" />
    //     </Menu.Item>
    //   ) : (
    //     <Menu.Item>
    //       {userState ? (
    //         <div className="logout__container">
    //           <strong style={{ color: "white" }}>Hello {userState.name}</strong>
    //           <button
    //             className="home__btn"
    //             style={{ color: "black" }}
    //             onClick={logOutUserbtn}
    //           >
    //             SignOut
    //           </button>
    //         </div>
    //       ) : (
    //         <button
    //           className="login__btn"
    //           onClick={() => {
    //             setlogIn(true);
    //           }}
    //         >
    //           LOG IN
    //         </button>
    //       )}
    //     </Menu.Item>
    //   )}
    // </Menu>
  );
}

export default Header;
